@font-face {
  font-family: 'CreatoDisplay';
  src: url('/public/fonts/CreatoDisplay-Regular.woff2') format('woff2'),
       url('/public/fonts/CreatoDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CreatoDisplay';
  src: url('/public/fonts/CreatoDisplay-Medium.woff2') format('woff2'),
       url('/public/fonts/CreatoDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CreatoDisplay';
  src: url('/public/fonts/CreatoDisplay-Bold.woff2') format('woff2'),
       url('/public/fonts/CreatoDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'CreatoDisplay', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#00264c;
  overflow-x: hidden;
  padding-top: 120px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b, strong {
  font-weight: 500;
}

.container {
  max-width: 1170px !important;
  padding: 0 3.5rem;
}

section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

section, .container, .container-fluid {
  position: relative;
  word-wrap: break-word;
}

.container-fluid  {
  padding-left: 60px;
  padding-right: 60px;
}

.br-none {
  border-radius: 0px;
}

@media (max-width: 992px) {
  body {
    padding-top: 100px;
  }
}

@media (max-width: 768px) {
  .container,
  .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/********************************/
/**********[ Navbar ]************/
/********************************/

.navbar_main {
  height: 120px;
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.navbar_main .container-fluid {
  position: initial;
}

.navbar_main .nav-link {
  font-size: 22px;
  color: #00264c;
  font-weight: 400;
}

.navbar_main .nav-link.active {
  color: #0457AC;
}

.navbar_main .navbar-nav {
  gap: 30px;
}

.navbar_main .logo {
  max-width: 300px;
}

.navbar_main .logo img {
  width: 100%;
}

.navbar_main .lang-selector {
  gap: 10px;
  margin-left: 40px;
}

.navbar_main .lang-selector button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.navbar_main .menu {
  display: flex;
}

.navbar_main .hamburger {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
}

.navbar_main .bar {
  display: block;
  background-color: black;
  width: 32px;
  /* The following 2 properties are essential in creating the "X" when the hamburger is clicked. If you change just 1 of the 2, then you need to find the proper combination for the other one */
  height: 2px;
  margin: 6px auto;

  /*    -webkit-transition: all 300ms ease;  */
  transition: all 300ms ease-in-out;
}

.navbar_main .hamburger.active .bar:nth-child(2) {
  opacity: 0
}

.navbar_main .hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.navbar_main .hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 992px) {
  .navbar_main {
    height: 100px;
  }

  .navbar_main .hamburger {
    display: block;
  }

  .navbar_main .menu {
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    background-color: #fff;
    left: 0;
    width: 100%;
    flex-direction: column-reverse;
  }

  .navbar_main .menu.active {
    display: flex;
  }

  .navbar_main .navbar-nav.menu-list {
    flex-direction: column;
    gap: 0;
    margin: 0 !important;
    padding: 20px 40px;
  }

  .navbar_main .lang-selector {
    margin: 0;
    padding: 10px 40px 0px;
  }

  .navbar_main .logo {
    max-width: 250px;
  }
  
}

/*******************************/
/**********[ Cover ]************/
/*******************************/

.jumbotron {
  min-height: 550px;
  display: flex;
  align-items: center;
  /*background-color: #666;*/
  overflow: hidden;
  position: relative;
}

.jumbotron .caption {
  max-width: 720px;
  margin: auto;
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
}

.jumbotron .caption .lead {
  font-size: 32px;
  margin: 30px 0;
}

.jumbotron .video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: black;
}

.jumbotron .video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  min-width: 1700px;
  height: auto;
  transform: translate(-50%, -50%);
  opacity: .8;
}

@media (max-width: 768px) {
  .jumbotron .caption .lead {
    font-size: 24px;
  }
}

/*******************************/
/**********[ Intro ]************/
/*******************************/

.intro {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}

.intro .text-wrapper {
  margin-right: 160px;
  padding: 60px;
  background-color: #0457AC;
  position: relative;
  z-index: 1;
}

.intro .text-wrapper .mbr-desc {
  margin-bottom: 20px;
}
.intro .mbr-desc,
.intro .mbr-text {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 400;
  margin: 50px 0;
}
.mbr-section-btn {
  margin-left: -0.6rem;
  margin-right: -0.6rem;
  font-size: 0;
}
.intro .mbr-section-btn .btn:first-child {
  margin-left: 9.6px;
}
.intro .mbr-section-btn .btn {
  height: 21px;
  min-width: 70px;
  position: relative;
  overflow: visible;
  padding: 0 12px 0 0;
  justify-content: flex-start;
  margin-left: 28px;
  box-shadow: none;
}
.intro .image-wrapper {
  margin: -28rem 160px 13rem;
  position: relative;
}
.intro .image-wrapper .img-absolute {
  position: absolute;
  right: -8rem;
  bottom: -13rem;
  width: 850px;
  height: 600px;
  object-fit: cover;
}

.intro .image-wrapper img {
  width: 420px;
  height: 560px;
  object-fit: cover;
  position: relative;
}

.btn-info-outline, .btn-info-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .intro .text-wrapper {
    margin: 0;
  }
  .intro .image-wrapper img {
    opacity: 0;
  }
  .intro .image-wrapper .img-absolute {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .intro {
    padding: 0;
  }
  .intro .image-wrapper {
    display: none;
  }
}

@media (max-width: 768px) {
  .intro .text-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/*******************************/
/**********[ About ]************/
/*******************************/

.about {
  padding: 5rem 0;
  position: relative;
  z-index: 1;
}

.about .card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  background-color: transparent;
}

.about .card-container {
  min-height: 40rem; /*50rem;*/
  margin-top: -1rem;
  padding: 60px;
  background: #3ad988;
  color: #00264c;
}

.about .card-container .mbr-text {
  font-size: 24px;
}

.about .card-container .mbr-section-title {
  margin: 60px 0px;
  font-weight: 700;
}

.about .card .img-cover {
  width: 45%;
  height: 600px;/*700px;*/
  object-fit: cover;
}

.about .profile-block {
  background-color: #e9f4ff;
  margin: 4rem 0px;
  color: #0457ac;
}

.about .profile-block .profile-title {
  font-weight: 700;
  font-size: 32px;
}

.about .profile-block .profile-subtitle {
  margin-bottom: 30px;
  font-size: 24px;
}

.about .profile-block .profile-job {
  font-size: 18px;
}

.about .profile-block .profile-description {
  font-size: 21px;
  color: #666;
  margin: 30px 0px 0px;
}

.about .profile-block .profile-info-col {
  width: 100%;
  padding: 60px;
}

.about .profile-block .profile-photo-col {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.about .profile-block .profile-photo {
  width: 100%;
}

.about .profile-block .profile-link {
  display: inline-flex;
  margin-top: 40px;
  color: #0457ac;
  align-items: center;
}

.about .profile-block .profile-link span {
  font-size: 21px;
  margin-left: 10px;
}

.about .supported-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0px 40px 40px;
}

.about .supported-wrapper h3 {
  font-weight: 500;
  color: #0457ac;
  font-size: 32px;
}

.about .logos {
  display: flex;
  align-items: center;
  gap: 40px;
}

.about .logos .img-br-1 {
  width: 180px;
}

.about .logos .img-br-2 {
  width: 80px;
}

.about .logos .img-br-3 {
  width: 80px;
}

@media (max-width: 991px) {
  .about {
    padding-top: 0;
  }
  .about .card {
    flex-direction: column;
  }
  .about .card-container {
    min-height: 20rem;
    margin: 0;
  }
  .about .card-container .mbr-section-title {
    margin-top: 0;
  }
  .about .card .img-cover {
    width: 100%;
  }
  .about .container-fluid {
    padding: 0;
  }
  .about .logos .img-br-1 {
    width: 120px;
  }
  .about .logos .img-br-2 {
    width: 50px;
  }
  .about .logos .img-br-3 {
    width: 40px;
  }
  .about .profile-block .profile-photo-col {
    padding: 0px 60px;
  }
  .about .profile-block .profile-photo {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .about .card-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .about .profile-container,
  .about .supported-container {
    padding: 0px;
  }
  .about .supported-wrapper {
    flex-direction: column;
    justify-content: start;
  }
  .about .supported-wrapper h3 {
    font-size: 24px;
    margin-bottom: 20px;
    width: 100%;
  }
  .about .supported-wrapper .logos {
    width: 100%;
  }
  .about .profile-block {
    margin-bottom: 40px;
  }
  .about .profile-block .profile-info-col {
    padding: 40px
  }
  .about .profile-block .profile-photo-col {
    padding: 0px;
  }
}

/**********************************/
/**********[ Services ]************/
/**********************************/

.services {
  background-color: #e9f4ff;
  padding-top: 10rem;
  margin-top: -150px;
}

.services .title-wrapper .mbr-section-title {
  padding-top: 20px;
  margin-bottom: 80px;
  color: #0457ac;
  font-weight: 700;
}

.services .cards .card {
  margin-bottom: 80px;
  border: none;
  background-color: transparent;
}

.services .cards .card .card-wrapper {
  position: relative;
  width: 100%;
  min-height: 510px;
}

.services .cards .card .card-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 510px;
  object-fit: cover;
  pointer-events: visible;
}

.services .cards .card .card-wrap {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  transition: all .3s ease;
}

.services .card-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #0457AC;
}

.services p {
  font-size: 22px;
  color: #666;
}

@media (max-width: 992px) {
  .services {
    padding-top: 8rem;
  }
}

/*********************************/
/**********[ Contact ]************/
/*********************************/

.contact {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #0457AC;
  color: white;
}

.contact .mbr-section-title {
  font-weight: 700;
  margin-bottom: 40px;
}

.contact .mbr-section-subtitle {
  margin-bottom: 40px;
}

.contact .form-group {
  margin-bottom: 20px !important;
}

.contact .form-group .form-control {
  min-height: 50px;
  padding: 10px 25px;
  background: transparent;
  box-shadow: none;
  border: none !important;
  border-bottom: 2px solid #3ad988 !important;
  border-radius: 0;
  font-size: 1.3rem;
  color: white;
}

.contact .form-group .form-control::placeholder {
  color: rgba(255,255,255,.7);
}

.contact .mbr-section-btn {
  margin-top: 30px;
}

.contact .mbr-section-btn .btn:first-child {
  margin-left: 25px;
}
.contact .mbr-section-btn .btn {
  border: none;
  color: #0457AC;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10px 25px;
  background-color: #3ad988;
}

.contact .location-block {
  display: flex;
  gap: 20px;
  margin: 20px 0px;
  font-size: 21px;
}

.contact .location-block span {
  color: rgba(255,255,255,.8);
}

.contact a {
  color: #3ad988
}

.contact .contact-text {
  font-size: 1.75rem;
  line-height: 1.35;
  margin-bottom: 70px;
}

.contact .contact-block {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .contact .contact-text {
    font-size: 24px;
  }
}


/********************************/
/**********[ Footer ]************/
/********************************/

.footer {
  background-color: #0457AC;
  color: white;
  font-size: 21px;
}

.footer .wrapper {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 50px;
}

.footer .social {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer .logos {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 991px) {
  .footer .wrapper {
    flex-direction: column;
    gap: 20px
  }
  .footer {
    font-size: 18px;
  }
}

/************************************/
/**********[ Sweetalert ]************/
/************************************/


.swal-modal .swal-title {
  color: black;
  font-weight: 500;
}
.swal-modal .swal-text {
  color: black;
  text-align: center;
}
.swal-modal .swal-button--confirm {
  background-color: #0457AC !important;
  font-weight: 400;
  font-size: 1rem;
}

/*******************************************/
/**********[ Commercial Banner ]************/
/*******************************************/

.commercial-banner {
  background-color: #3ad988;
}

.commercial-banner .banner-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.commercial-banner .text {
  color: #0457AC;
  font-size: 24px;
  text-decoration: none;
  font-weight: 500;
  line-height: 1.4;
  margin: 0px;
  padding: 20px 0;
  display: block;
}

.commercial-banner .icon {
  max-width: 64px;
}

@media (max-width: 991px) {
  .commercial-banner .text {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .commercial-banner .banner-wrapper {
    flex-direction: column;
  }
  .commercial-banner .icon {
    display: none;
  }
}